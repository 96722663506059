import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from 'qs';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    telefono: '',
    email: '',
    nombreEmpresa: '',
    rutEmpresa: '',
    mensaje: '', // Nuevo campo de mensaje
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación básica
    const { nombre, apellidos, telefono, email, nombreEmpresa, rutEmpresa, mensaje } = formData;
    if (!nombre || !apellidos || !telefono || !email || !nombreEmpresa || !rutEmpresa || !mensaje) {
      Swal.fire({
        icon: 'error',
        title: 'Campos obligatorios',
        text: 'Por favor, completa todos los campos.',
      });
      return;
    }

    try {
      // Enviar datos al servidor
      const response = await axios.post('https://grhin.cl/enviar_email.php', qs.stringify(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Manejar respuesta del servidor
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Correo enviado',
          text: response.data.success,
        });

        // Reiniciar el formulario
        setFormData({
          nombre: '',
          apellidos: '',
          telefono: '',
          email: '',
          nombreEmpresa: '',
          rutEmpresa: '',
          mensaje: '', // Reiniciar campo mensaje
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al enviar',
          text: response.data.error || 'Ocurrió un error inesperado.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error del servidor',
        text: 'No se pudo enviar el formulario. Intenta nuevamente más tarde.',
      });
    }
  };

  return (
    <section id="contact" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-[#115E59]">¿Listo para empezar?</h2>
          <p className="mt-4 text-xl text-[#0D9488]">
            Contáctanos para conocer más sobre cómo GRHIN puede ayudar a tu empresa.
          </p>
          <form onSubmit={handleSubmit} className="mt-8 max-w-3xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                name="nombre"
                placeholder="Nombre"
                value={formData.nombre}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="text"
                name="apellidos"
                placeholder="Apellidos"
                value={formData.apellidos}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="tel"
                name="telefono"
                placeholder="Teléfono"
                value={formData.telefono}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="text"
                name="nombreEmpresa"
                placeholder="Nombre de la empresa"
                value={formData.nombreEmpresa}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
              <input
                type="text"
                name="rutEmpresa"
                placeholder="RUT de la empresa"
                value={formData.rutEmpresa}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2"
              />
            </div>
            <textarea
              name="mensaje"
              placeholder="Escribe tu mensaje"
              value={formData.mensaje}
              onChange={handleChange}
              className="mt-4 w-full border border-gray-300 rounded-md p-2"
              rows="4"
            />
            <button
              type="submit"
              className="mt-4 w-full bg-[#115E59] text-white py-2 px-4 rounded-md hover:bg-[#0D9488] transition"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
